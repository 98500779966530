import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Toolbar } from "@mui/material";
import Dashboard from "../Layout/dashboard";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import {
  GET_ALL_USERS,
  S_ADMIN_ALL_ORGANIZATION,
  ADD_SUB_ADMIN,
  DEL_SUB_ADMIN,
  SUB_ADMIN_TOGGLE_STATUS,
} from "../../../../Auth_API";
import { UserLogin } from "../../../../context/AuthContext";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import AppLoader from "../../../Loader/AppLoader";
import customCloseIcon from "../../../../assets/img/cross.png";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function createData(id: Number, email: string, name: String, is_active: boolean) {
  return { id, email, name, is_active };
}

export default function User() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [userData, setUserData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [organization, setOrganization] = useState([]);
  const { superToken, showToast, setIsErrorOpen, isLoading, setIsLoading } = UserLogin();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [onSearchUser, setSearchUser] = useState("");
  const [adminToggleModal, setAdminToggleModal] = useState(false);
  const [adminStatusMsg, setAdminStatusMsg] = useState(null);
  const [adminToggleId, setAdminToggleId] = useState("");
  const [updateNewData, setUpdateNewData] = useState(false);

  /* Functions for Search Input Field */
  const handleSearchClick = () => {
    setIsExpanded(!isExpanded);
  };

  let userToken = superToken.split("\\").join("").split('"').join("");
  const rows = userData.map((item, index) => {
    return createData(item.id, item.email, item.organization_name, item.is_active);
  });

  const filteredRows =
    onSearchUser === ""
      ? rows
      : rows.filter((row) => {
        return Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(onSearchUser.toLowerCase())
        );
      });



  const onSubmitSubAdmins = async (e) => {
    e.preventDefault();
    setShowModal(false);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!email.match(emailRegex)) {
      setEmailError(true);
      return;
    }
    setIsLoading(true);

    try {
      const response = await axios.post(
        ADD_SUB_ADMIN,
        {
          email: email,
          organization: parseInt(selectedOrganization),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${userToken}`,
          },
        }
      );
      console.log(response)
      if (response.status === 204) {
        console.log(response.data, "New admin added");

        if (response.data.message) {
          showToast(response.data.message);
        } else if (response.data.email) {
          showToast(response.data.email);
        }

        // Assuming response.data contains the newly added admin details
        const newAdmin = {
          email: email, // The email of the new admin
          organization: selectedOrganization, // The organization ID
          status: "Active" // Assuming new admins are active by default
        };

        // // Update the userData state directly with the new admin
        // setUserData(prevUserData => [...prevUserData, newAdmin].sort((a, b) => a.email.localeCompare(b.email)));
        // Clear form fields
        setEmail("");
        setSelectedOrganization("");
        document.getElementById("selectedOrganization").value = "";
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.email) {
        showToast(`${error.response.data.email}`);
        setIsErrorOpen(true);
      }
      setEmail("");
      setSelectedOrganization("");
      document.getElementById("selectedOrganization").value = "";
    }
    setIsLoading(false);
  };


  // const onSubmitSubAdmins = async (e) => {
  //   e.preventDefault();
  //   setShowModal(false);
  //   const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  //   if (!email.match(emailRegex)) {
  //     setEmailError(true);
  //     return;
  //   }
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       ADD_SUB_ADMIN,
  //       {
  //         email: email,
  //         organization: parseInt(selectedOrganization),
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Token ${userToken}`,
  //         },
  //       }
  //     );
  //     if (response.data) {
  //       console.log(response.data, "dgsgsg");
  //       if (response.data.message) {
  //         showToast(response.data.message);
  //       } else if (response.data.email) {
  //         showToast(response.data.email);
  //       }
  //       fetchUsersData();
  //       setEmail("");
  //       setSelectedOrganization("");
  //       document.getElementById("selectedOrganization").value = "";
  //     }
  //   } catch (error) {
  //     if (error.response.data.email) {
  //       showToast(`${error.response.data.email}`);
  //       setIsErrorOpen(true);
  //     }
  //     setEmail("");
  //     setSelectedOrganization("");
  //     document.getElementById("selectedOrganization").value = "";
  //   }
  //   setIsLoading(false);
  // };

  const fetchUsersData = async () => {
    try {
      const response = await axios.get(GET_ALL_USERS, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken}`,
        },
      });
      console.log(response.data, "user data");
      const sortedData = response.data.sort((a, b) => a.email.localeCompare(b.email));
      setUserData(sortedData);
    } catch (error) {
      console.log("Failed to fetch users data", error);
    }
  };

  const fetchOrganization = async () => {
    try {
      const response = await axios.get(S_ADMIN_ALL_ORGANIZATION, {
        headers: {
          Authorization: `Token ${userToken}`,
          "Content-Type": "application/json",
        },
      });
      setOrganization(response.data);
    } catch (error) {
      console.log("Failed to fetch organizations", error.message);
    }
  };

  useEffect(() => {
    fetchUsersData();
    fetchOrganization();
  }, []);

  const onDeletedSubAdmin = async (e, id) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("supertoken", superToken);
    console.log("id", id);
    try {
      const response = await axios.post(
        `${DEL_SUB_ADMIN}${id}/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${superToken}`,
          },
        }
      );
      console.log(response, "response after delete click ");
      if (response.status === 200) {
        showToast(response.data.message);
        setShowConfirmDeleteModal(true);
        console.log("otp sent to delete admin");
        setCountdown(120);
      }
    } catch (error) {
      showToast(`${error.response.data.error}`);
      setIsErrorOpen(true);
      console.log("Failed to delete Sub Admin", error);
    }
    setIsLoading(false);
  };

  const handleSendOTPToDelete = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("supertoken", superToken);
    console.log("id", adminRowId);
    try {
      setIsLoading(true);
      const response = await axios.delete(`${DEL_SUB_ADMIN}${adminRowId}/`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
        data: {
          otp: otpValues.join(""),
        },
      });
      console.log(response, "response after delete click ");

      if (response.status === 204) {
        showToast("Admin has been deleted successfully!");
        setShowConfirmDeleteModal(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsErrorOpen(true);
      showToast(error.response.data.error);
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const toggleStatus = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${SUB_ADMIN_TOGGLE_STATUS}${id}/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${userToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      fetchUsersData();
      showToast(`${data.status === "active" ? "Active" : "Inactive"} Request Successful!`);
    } catch (error) {
      console.log("Failed to toggle status", error);
    }
    setIsLoading(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [adminRowId, setAdminRowId] = useState(null);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [countdown, setCountdown] = useState(120);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Format the remaining time in MM:SS
  const formatTime = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Function for otp fields
  const digitValidate = (index, value) => {
    setOtpValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value.replace(/[^0-9]/g, "");
      return newValues;
    });
  };

  const tabChange = (index) => {
    const inputFields = document.querySelectorAll(".otp");
    if (inputFields.length > index) {
      if (inputFields[index - 1].value !== "") {
        inputFields[index].focus();
      } else if (index > 1 && inputFields[index - 1].value === "") {
        inputFields[index - 2].focus();
      }
    }
  };

  return (
    <>
      {isLoading ? <AppLoader /> : null}
      <div style={{ background: "#EEEEF4" }}>
        <Dashboard title="Organizational Admin">
          <div className="user-table">
            <div className="d-sm-flex justify-content-between add-btn-admin">
              <div className="search-field-organ mb-sm-0 mb-4">
                <Toolbar style={{ padding: "0px" }}>
                  <form
                    role="search"
                    style={{
                      borderRadius: "18px",

                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    <div className={`search-container ${isExpanded ? "expanded" : ""}`}>
                      <button type="button" onClick={handleSearchClick} className="search-button">
                        <i className="fa fa-search"></i>
                      </button>
                      <input
                        className="search-input"
                        type="text"
                        placeholder="Search"
                        onClick={handleSearchClick}
                        onBlur={() => setIsExpanded(false)}
                        value={onSearchUser}
                        onChange={(e) => setSearchUser(e.target.value)}
                      />
                    </div>
                  </form>
                </Toolbar>
              </div>
              <button
                type="button"
                style={{
                  background: "linear-gradient(180deg, #56367F 0%, #3A5697 100%)",
                  border: "none",
                  height: "40px",
                  color: "white",
                  width: "120px",
                  borderRadius: "8px",
                }}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add Admin
              </button>
            </div>
          </div>

          <div style={{ overflowX: "auto" }}>
            <div className="org-table">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label="custom pagination table">
                  <TableHead>
                    <TableRow
                      className=""
                      style={{ background: "linear-gradient(180deg, #56367F 0%, #3A5697 100%)" }}
                    >
                      <TableCell>
                        <strong style={{ fontWeight: "700", color: "white" }}>ID</strong>
                      </TableCell>
                      <TableCell>
                        <strong style={{ fontWeight: "700", color: "white" }}>Email</strong>
                      </TableCell>
                      <TableCell>
                        <strong style={{ fontWeight: "700", color: "white" }}>
                          Organization Name
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong style={{ fontWeight: "700", color: "white" }}>Status</strong>
                      </TableCell>
                      <TableCell>
                        <strong style={{ fontWeight: "700", color: "white" }}>Action</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : filteredRows
                    ).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {row.is_active === true ? (
                            <span className="active-status" style={{ background: "#50B482" }}>
                              Active
                            </span>
                          ) : (
                            <span
                              className="inactive-status bg-warning"
                              style={{ backgroundColor: "rgb(255, 193, 7)" }}
                            >
                              Inactive
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={row.is_active === true ? false : true}
                              onClick={() => {
                                setAdminStatusMsg(row.is_active);
                                setAdminToggleId(row.id);
                                setAdminToggleModal(true);
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                          <button
                            className="btn-del mt-1"
                            onClick={(e) => {
                              onDeletedSubAdmin(e, row.id);
                              setAdminRowId(row.id);
                            }}
                          >
                            <FaTrash />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                        colSpan={5}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              {/* Modal to add admin */}
              <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{
                  display: showModal ? "block" : "none",
                  overflowY: "hidden",
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <form onSubmit={(e) => onSubmitSubAdmins(e)}>
                      <div
                        className="modal-header"
                        style={{
                          background: "linear-gradient(180deg, #56367F 0%, #3A5697 100%)",
                          color: "white",
                        }}
                      >
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add Admin
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          style={{
                            background: `url(${customCloseIcon}) no-repeat center center`,
                            backgroundSize: "cover",
                            border: "none",
                            opacity: "1",
                          }}
                          onClick={() => {
                            setShowModal(false);
                            setEmail("");
                            setSelectedOrganization("");
                            document.getElementById("selectedOrganization").value = "";
                            setEmailError(false);
                          }}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label htmlFor="email" className="small mb-1">
                            Email <span className="required">*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="name@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {emailError && <p className="error-message">Email is invalid</p>}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="selectOption" className="small mb-1">
                            Organization <span className="required">*</span>
                          </label>
                          <select
                            id="selectedOrganization"
                            as="select"
                            name="selectOrganization"
                            className="form-control"
                            required
                            onChange={(e) => setSelectedOrganization(e.target.value)}
                          >
                            <option value="">Select Organization</option>
                            {organization.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn org-btn-close"
                          style={{ backgroundColor: "#808080" }}
                          onClick={() => {
                            setEmail("");
                            setSelectedOrganization("");
                            document.getElementById("selectedOrganization").value = "";
                            setEmailError(false);
                            setShowModal(false);
                          }}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn org-btn">
                          Add
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Inactive active org */}
              <div
                className={`modal fade ${adminToggleModal ? "show" : ""}`}
                style={{
                  display: adminToggleModal ? "block" : "none",
                  overflowY: "hidden",
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div
                      className="modal-header"
                      style={{
                        background: "linear-gradient(180deg, #56367F 0%, #3A5697 100%)",
                        color: "white",
                      }}
                    >
                      <h5 className="modal-title" id="exampleModalLabel">
                        Attention
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        style={{
                          background: `url(${customCloseIcon}) no-repeat center center`,
                          backgroundSize: "cover",
                          border: "none",
                          color: "#50B482",
                          opacity: "1",
                        }}
                        onClick={() => {
                          setAdminToggleModal(false);
                        }}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>{`Are you sure you want to ${adminStatusMsg === true ? "inactive" : "active"
                        } the admin?`}</p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn org-btn-close"
                        style={{ backgroundColor: "#808080" }}
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setAdminToggleModal(false);
                        }}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn org-btn"
                        style={{ background: "linear-gradient(90deg, #56367F 0%, #3A5697 100%)" }}
                        onClick={() => {
                          toggleStatus(adminToggleId);
                          setAdminToggleModal(false);
                        }}
                      >
                        {adminStatusMsg === true ? "Inactive" : "Active"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Dashboard>
      </div>

      {/* {confirm otp to delete admin modal} */}

      <div
        className={`modal fade ${showConfirmDeleteModal ? "show" : ""}`}
        style={{
          display: showConfirmDeleteModal ? "block" : "none",
          background: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <form>
              <div
                className="modal-header pb-0  d-flex justify-content-end"
                style={{ background: "white", border: "none" }}
                onClick={() => {
                  setShowConfirmDeleteModal(false);
                }}
              >
                <CloseIcon className="btn-close-otp" />
              </div>
              <div className="modal-body pb-5 d-flex flex-column align-items-center gap-1">
                <h3 className=" fw-bold fs-2 m-0 mt-2">Deleting Admin account</h3>
                <p className="text-secondary text-center mt-1">
                  Please confirm the OTP to completely delete the account.
                </p>
                <form className="verify-otp-form d-flex gap-2">
                  {otpValues.map((value, index) => (
                    <input
                      key={index}
                      className="otp mb-sm-0 mb-3"
                      name="otp"
                      type="text"
                      value={value}
                      onChange={(e) => digitValidate(index, e.target.value)}
                      onKeyUp={() => tabChange(index + 1)}
                      maxLength={1}
                    />
                  ))}
                </form>
                <div className="otp-timer mt-4 ">
                  <p>OTP Validate: {formatTime()} </p>
                </div>
                <button
                  type="submit"
                  className={`login-form-button col-sm-7 col-10 text-capitalize ${isLoading ? "disabled" : ""}`}
                  onClick={(e) => handleSendOTPToDelete(e)}
                >
                  Confirm
                </button>
                <button
                  type="submit"
                  className={`login-form-button text-capitalize col-sm-7 mt-1 col-10 ${countdown > 0 ? "disabled" : ""
                    }`}
                  onClick={(e) => onDeletedSubAdmin(e, adminRowId)}
                >
                  Resend OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
