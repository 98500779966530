import React, { useState, useEffect } from "react";
import { FaUser, FaBars } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTour } from "@reactour/tour";
import LogoutModal from "./logoutadmin"; // Import the modal component
import profile from "../../../../assets/img/new-profile.png"

const Topnav = ({ title, onOpen }) => {
  const navigate = useNavigate();
  const { setIsOpen } = useTour();
  const location = useLocation();
  const [showLogoutModal, setShowLogoutModal] = useState(false); // State for modal visibility

  useEffect(() => {
    console.log("location", location.pathname);
  }, [location]);

  const { pathname } = location;

  // Function to handle actual logout
  const onSuperAdminLogout = () => {
    localStorage.removeItem("SuperToken");
    navigate("/");
    window.location.reload();
  };

  // Function to show logout modal
  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  return (
    <div className="top-nav d-flex align-items-center justify-content-between">
      <div className="title-top">
        <h1 className="title m-0">{title}</h1>
      </div>
      <div className="dropdown">
        <div className="d-flex align-items-center">
          <button className="btn btn-toggle" type="button" onClick={onOpen}>
            <FaBars />
          </button>

          <div className="dropdown">
            <button
              className="btn btn-user"
              type="button"
              id="userDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              color="50B482"
            >
              <img src={profile} alt="" className="profile-image" style={{border:"none"}}/>
            </button>
            <ul className="dropdown-menu" aria-labelledby="userDropdown">
              {pathname === "/s_admin_dashboard" || pathname === "/" ? (
                <li>
                  <Link onClick={() => setIsOpen(true)} className="dropdown-item">
                    Admin guide
                  </Link>
                </li>
              ) : null}
              <li>
                <Link to="/s_admin_dashboard/sub_admin" className="dropdown-item">
                  Manage admins
                </Link>
              </li>
              <li>
                <button className="dropdown-item" onClick={handleLogout}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showLogoutModal && (
        <LogoutModal
          onClose={() => setShowLogoutModal(false)}
          onConfirm={onSuperAdminLogout}
        />
      )}
    </div>
  );
};

export default Topnav;
